/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //$('.gallery').each(function( index ) {
          //var gallery = $(this).attr('id');
          //var lightbox = $('#' + gallery + ' a').simpleLightbox({ /* options */ });
        //});

        $(function() {
          
          // Run the scrNav when scroll
          $(window).on('scroll', function(){
            scrNav();
          });
          
          // scrNav function 
          // Change active dot according to the active section in the window
          function scrNav() {
            var sTop = $(window).scrollTop();

            $('.scroll-spy').each(function() {
              var id = $(this).attr('id'),
                  offset = $(this).offset().top-250,
                  height = $(this).height();
              if(sTop >= offset && sTop < offset + height) {
                $('#primary-navigation li').removeClass('active');
                //$('#navbar').find('[data-scroll="' + id + '"]').addClass('active');
                $('#primary-navigation .menu-' + id).addClass('active');
              }
            });
          }
          //scrNav();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.menu-services').removeClass('active');
        //$('#primary-navigation ul li.active a').css('border-bottom','5px solid red');

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the ini

        $(window).scroll(function() {
          var scroll = $(window).scrollTop();
          if (scroll >= 120) {
            $('.home-navigation, .main-header ').addClass('scrolled');
          } else {
            $('.home-navigation, .main-header').removeClass('scrolled');
          }

        }).scroll();​
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


/*

var topMenu = $("#primary-navigation");
        var topMenuHeight = topMenu.outerHeight()+15;
        console.log(topMenu);
        
        // All list items
        //var menuItems = topMenu.find("li a");
        var menuItems =  topMenu.find('a[href*="#"]');
        console.log(menuItems.length);
        
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
          var item = $(this).attr("href").replace('https://tarpontowers.com/','');
          console.log(item);
          if (item.length) { 
            return item; 
          }
        });

        $(window).scroll(function(){
           // Get container scroll position
           var fromTop = $(this).scrollTop()+topMenuHeight;

           console.log(fromTop);

           // Get id of current scroll item
           var cur = scrollItems.map(function(){
             if ($(this).offset().top < fromTop)
               console.log(this)
               return this;
           });
           // Get the id of the current element
           cur = cur[cur.length-1];
           var id = cur && cur.length ? cur[0].id : "";
           // Set/remove active class
           menuItems
             .parent().removeClass("active")
             .end().filter("[href='#"+id+"']").parent().addClass("active");
        });​


// Simple Lightbox - Already Installed
var lightbox = $('.gallery-gallery a').simpleLightbox();

// Slick Slideshow - Already Installed
$('.home-slideshow').slick({
  infinite: true,
  cssEase: 'linear',
  autoplay: true,
  arrows: true,
  dots: true
});

// Smooth Scroll
var topOffset = 0;
$(".scroll-to, .scroll-to-li a").not('[href="#"]').not('[href="#0"]').click(function(t){if(location.pathname.replace(/^\//,"")===this.pathname.replace(/^\//,"")&&location.hostname===this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(t.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-topOffset},1e3,function(){var t=$(e);if(t.focus(),t.is(":focus"))return!1;t.attr("tabindex","-1"),t.focus()}))}});

*/
